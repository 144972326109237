<template>
  <div class="cd__chart">
    <svg viewBox="0 0 880 400" class="cd__chart-svg">
      <g>
        <path d="M0,0 L880,0 L880,575 L0,575 Z" fill="#FFFFFF" stroke="#000000" fill-opacity="0" stroke-width="1" stroke-opacity="0"></path>
      </g>
      <g opacity="0.8">
        <path d="M142.5,378.6 L126.5,378.6 A314,314,0,0,1,688.61,186.15 L675.97,195.95 A298,298,0,0,0,142.5,378.6 Z" fill="#FF4D4D" stroke="#000000" stroke-width="0" stroke-opacity="0"></path>
      </g>
      <g opacity="0.8">
        <path d="M675.97,195.95 L688.61,186.15 A314,314,0,0,1,739.13,281.57 L723.91,286.51 A298,298,0,0,0,675.97,195.95 Z" fill="#FFC569" stroke="#000000" stroke-width="0" stroke-opacity="0"></path>
      </g>
      <g opacity="0.8">
        <path d="M723.91,286.51 L739.13,281.57 A314,314,0,0,1,754.5,378.6 L738.5,378.6 A298,298,0,0,0,723.91,286.51 Z" fill="#4ADB4A" stroke="#000000" stroke-width="0" stroke-opacity="0"></path>
      </g>
      <g>
        <path d="M126.5,378.6 L125.5,378.6 A315,315,0,0,1,755.5,378.6 L754.5,378.6 A314,314,0,0,0,126.5,378.6 Z" fill="#000000" fill-opacity="1" stroke-width="0" stroke-opacity="0"></path>
      </g>
      <path cs="100,100" d="M126.93002,379.09998 L141.93002,379.09998" fill="none" stroke-width="1" stroke-opacity="1"
        stroke="#000000"></path>
      <text y="15" fill="#000000" font-family="" font-size="30px" opacity="1" text-anchor="middle"
        transform="translate(160,379)">
        <tspan y="15" x="0">0</tspan>
      </text>
      <path cs="100,100" d="M129.40655,339.73658 L137.34347,340.73924" fill="none" stroke-width="1" stroke-opacity="1"
        stroke="#000000"></path>
      <path cs="100,100" d="M136.7971,300.99395 L144.54577,302.98347" fill="none" stroke-width="1" stroke-opacity="1"
        stroke="#000000"></path>
      <path cs="100,100" d="M148.98511,263.48311 L156.42333,266.42811" fill="none" stroke-width="1" stroke-opacity="1"
        stroke="#000000"></path>
      <path cs="100,100" d="M165.77837,227.79561 L172.78883,231.64964" fill="none" stroke-width="1" stroke-opacity="1"
        stroke="#000000"></path>
      <path cs="100,100" d="M186.91204,194.49428 L199.0473,203.31106" fill="none" stroke-width="1" stroke-opacity="1"
        stroke="#000000"></path>
      <text y="15" fill="#000000" font-family="" font-size="30px" opacity="1"
        text-anchor="middle" transform="translate(221,221)">
        <tspan y="15" x="0">20</tspan>
      </text>
      <path cs="100,100" d="M212.05283,164.10428 L217.88458,169.58066" fill="none" stroke-width="1" stroke-opacity="1"
        stroke="#000000"></path>
      <path cs="100,100" d="M240.80426,137.1049 L245.90365,143.26901" fill="none" stroke-width="1" stroke-opacity="1"
        stroke="#000000"></path>
      <path cs="100,100" d="M272.71289,113.92193 L276.9995,120.67655" fill="none" stroke-width="1" stroke-opacity="1"
        stroke="#000000"></path>
      <path cs="100,100" d="M307.2755,94.92097 L310.68174,102.15958" fill="none" stroke-width="1" stroke-opacity="1"
        stroke="#000000"></path>
      <path cs="100,100" d="M343.94704,80.40168 L348.58229,94.66753" fill="none" stroke-width="1" stroke-opacity="1"
        stroke="#000000"></path>
      <text y="15" fill="#000000" font-family="" font-size="30px" opacity="1"
        text-anchor="middle" transform="translate(356,123)">
        <tspan y="15" x="0">40</tspan>
      </text>
      <path cs="100,100" d="M382.14915,70.59304 L383.6482,78.45134" fill="none" stroke-width="1" stroke-opacity="1"
      stroke="#000000"></path>
      <path cs="100,100" d="M421.27938,65.64974 L421.78171,73.63396" fill="none" stroke-width="1" stroke-opacity="1"
        stroke="#000000"></path>
      <path cs="100,100" d="M460.72062,65.64974 L460.21829,73.63396" fill="none" stroke-width="1" stroke-opacity="1"
        stroke="#000000"></path>
      <path cs="100,100" d="M499.85085,70.59304 L498.3518,78.45134" fill="none" stroke-width="1" stroke-opacity="1"
        stroke="#000000"></path>
      <path cs="100,100" d="M538.05296,80.40168 L533.41771,94.66753" fill="none" stroke-width="1" stroke-opacity="1"
        stroke="#000000"></path>
      <text y="15" fill="#000000" font-family="" font-size="30px" opacity="1"
        text-anchor="middle" transform="translate(525,123)">
        <tspan y="15" x="0">60</tspan>
      </text>
      <path cs="100,100" d="M574.7245,94.92097 L571.31826,102.15958" fill="none" stroke-width="1" stroke-opacity="1"
        stroke="#000000"></path>
      <path cs="100,100" d="M609.28711,113.92193 L605.0005,120.67655" fill="none" stroke-width="1" stroke-opacity="1"
        stroke="#000000"></path>
      <path cs="100,100" d="M641.19574,137.1049 L636.09635,143.26901" fill="none" stroke-width="1" stroke-opacity="1"
        stroke="#000000"></path>
      <path cs="100,100" d="M669.94717,164.10428 L664.11542,169.58066" fill="none" stroke-width="1" stroke-opacity="1"
        stroke="#000000"></path>
      <path cs="100,100" d="M695.08796,194.49428 L682.9527,203.31106" fill="none" stroke-width="1" stroke-opacity="1"
        stroke="#000000"></path>
      <text y="15" fill="#000000" font-family="" font-size="30px" opacity="1"
        text-anchor="middle" transform="translate(660,221)">
        <tspan y="15" x="0">80</tspan>
      </text>
      <path cs="100,100" d="M716.22163,227.79561 L709.21117,231.64964" fill="none" stroke-width="1" stroke-opacity="1"
        stroke="#000000"></path>
      <path cs="100,100" d="M733.01489,263.48311 L725.57667,266.42811" fill="none" stroke-width="1" stroke-opacity="1"
        stroke="#000000"></path>
      <path cs="100,100" d="M745.2029,300.99395 L737.45423,302.98347" fill="none" stroke-width="1" stroke-opacity="1"
        stroke="#000000"></path>
      <path cs="100,100" d="M752.59345,339.73658 L744.65653,340.73924" fill="none" stroke-width="1" stroke-opacity="1"
        stroke="#000000"></path>
      <path cs="100,100" d="M755.06998,379.09998 L740.06998,379.09998" fill="none" stroke-width="1" stroke-opacity="1"
        stroke="#000000"></path>
      <text y="15" fill="#000000" font-family="" font-size="30px" opacity="1"
        text-anchor="middle" transform="translate(704,379)">
        <tspan y="15" x="0">100</tspan>
      </text>
      <text y="60" fill="#FFC569" font-family="" font-size="120px" opacity="1" font-weight="bold"
        text-anchor="middle" transform="translate(441,222)">
        <tspan y="60" x="0">{{ value }}</tspan>
      </text>
      <text y="25" fill="#000000" font-family="" font-size="50px" opacity="1" font-weight="bold"
        text-anchor="middle" transform="translate(441,536)">
        <tspan y="25" x="0"></tspan>
      </text>
      <g>
        <text y="12" fill="#31708F" font-family="" font-size="24px" opacity="1" font-weight="bold"
          text-anchor="middle" transform="translate(441,20)" style="pointer-events: none;">
          <tspan y="12" x="0">Кількість балів</tspan>
        </text>
      </g>
    </svg>
    <div class="cd__chart-arrow" :style="'transform: translate(-50%, 0) rotate(' + arrowRotation + 'deg);'">
      <svg version="1.1" x="0px" y="0px" viewBox="0 0 16 16" style="enable-background:new 0 0 16 16;" xml:space="preserve" preserveAspectRatio="none">
	      <polygon style="fill:#FFAC29;" points="8,0 0,16 16,16 	"/>
      </svg>
    </div>
  </div>
</template>

<script>

export default {
  name: 'CdChart',

  props: {
    value: {
      type: String
    },
  },
  data: () => ({
    
  }),

  computed: {
    arrowRotation: function() {
      const degrees = (this.value / 100) * 180 - 90;
      return degrees;
    }
  },

  mounted() {

  },

  methods: {

  },
}
</script>

<style scoped lang="scss">
.cd__chart {
  width: 800px;
  max-width: 100%;
  position: relative;
  overflow: hidden;
  margin: 0 auto;

  &-svg {
    width: 100%;
    height: auto;
  }
  &-arrow {
    opacity: 0.7;
    width: 10px; 
    height: 75%;
    position: absolute;
    left: 50%;
    bottom: 8%;
    transform-origin: center bottom;

    svg {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
