var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.get_custom_data_groups,"item-key":"id","footer-props":{
      itemsPerPageOptions: [10, 20, 30, 100],
      itemsPerPageText: _vm.$t('modules.dialog.itemsPerPageText'),
      pageText: _vm.$t('modules.dialog.pageText'),
    }},scopedSlots:_vm._u([{key:"item.control",fn:function(ref){
    var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","color":"green","title":_vm.$t('modules.dialog.texts.settings.edit_bot')},on:{"click":function($event){return _vm.toggleAddAndEditGroupModal(true, item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("$iconedit")])],1),_c('v-btn',{attrs:{"icon":"","color":"red","title":_vm.$t('modules.dialog.texts.settings.delete_bot')},on:{"click":function($event){return _vm.toggleDeleteGroupModal(true, item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("$icondelete")])],1)]}}])}),_c('v-layout',{staticClass:"mt-5",attrs:{"justify-center":""}},[_c('v-btn',{attrs:{"color":"primary","height":"44","width":"184","elevation":"0"},on:{"click":function($event){return _vm.toggleAddAndEditGroupModal(true)}}},[_c('v-icon',{attrs:{"left":"","size":"14"}},[_vm._v("mdi-plus")]),_vm._v(" "+_vm._s(_vm.$t('modules.dialog.texts.settings.add_group')))],1)],1),[_c('div',{staticClass:"text-center"},[_c('v-dialog',{attrs:{"width":"500","scrollable":""},model:{value:(_vm.addGroupModalState),callback:function ($$v) {_vm.addGroupModalState=$$v},expression:"addGroupModalState"}},[_c('v-form',{ref:"addNewGroupForm"},[_c('v-card',[_c('v-btn',{attrs:{"icon":"","absolute":"","top":"","right":""},on:{"click":function($event){return _vm.toggleAddAndEditGroupModal(false)}}},[_c('v-icon',{attrs:{"size":"32","color":"black"}},[_vm._v(" mdi-close ")])],1),_c('v-card-title',{staticClass:"font-weight-bold text-h5 pl-7 pt-5",domProps:{"textContent":_vm._s(_vm.groupId
                ? _vm.$t('modules.dialog.texts.settings.group_parameters')
                : _vm.$t('modules.dialog.texts.settings.add_new_group'))}}),_c('v-card-text',{staticClass:"px-7 py-2"},[_c('v-text-field',{attrs:{"rules":[_vm.rules.required],"label":_vm.$t('modules.dialog.texts.settings.name'),"outlined":""},model:{value:(_vm.groupName),callback:function ($$v) {_vm.groupName=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"groupName"}}),_c('v-text-field',{attrs:{"rules":[_vm.rules.onlyDigits],"label":_vm.$t('modules.dialog.texts.settings.group_priority'),"outlined":"","type":"number"},model:{value:(_vm.groupPriority),callback:function ($$v) {_vm.groupPriority=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"groupPriority"}})],1),_c('v-card-actions',{staticClass:"px-7 pt-0 pb-9"},[_c('v-btn',{attrs:{"color":"primary","elevation":"0","width":"100%","height":"40"},domProps:{"textContent":_vm._s(_vm.groupId
                  ? _vm.$t('modules.dialog.texts.settings.save')
                  : _vm.$t('modules.dialog.texts.settings.add_new_group'))},on:{"click":function($event){return _vm.saveOrEditGroup(_vm.bot_settings.active_id)}}})],1)],1)],1)],1)],1)],[_c('v-dialog',{attrs:{"width":"500"},model:{value:(_vm.deleteGroupModalState),callback:function ($$v) {_vm.deleteGroupModalState=$$v},expression:"deleteGroupModalState"}},[_c('v-card',[_c('v-btn',{attrs:{"icon":"","absolute":"","top":"","right":""},on:{"click":function($event){return _vm.toggleDeleteGroupModal(false)}}},[_c('v-icon',{attrs:{"size":"32","color":"black"}},[_vm._v(" mdi-close ")])],1),_c('v-card-title',{staticClass:"font-weight-bold text-h5 pl-7 pt-5"},[_vm._v(" "+_vm._s(_vm.$t('modules.dialog.texts.settings.group_delete'))+" ")]),_c('v-card-text',[_c('v-sheet',{staticClass:"text-center ml-auto mr-auto d-flex align-center",attrs:{"width":"300","min-height":"100"}},[_c('div',[_vm._v(" "+_vm._s(_vm.$t("modules.dialog.texts.settings.sure_to_delete"))+" "+_vm._s(_vm.$t('modules.dialog.texts.settings.group'))+" "),_c('span',{staticClass:"font-weight-bold ml-1"},[_vm._v("\""+_vm._s(_vm.groupName)+"\"?")])])])],1),_c('v-card-actions',{staticClass:"px-7 pt-0 pb-9"},[_c('v-btn',{attrs:{"width":"100%","height":"40","color":"primary","elevation":"0"},on:{"click":function($event){return _vm.deleteGroup()}}},[_vm._v(_vm._s(_vm.$t('modules.dialog.texts.settings.delete_group')))])],1)],1)],1)]],2)}
var staticRenderFns = []

export { render, staticRenderFns }