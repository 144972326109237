<template>
  <div>
    <v-navigation-drawer
      v-model="modal"
      class="cd_modal"
      absolute
      temporary
      right
      ref="drawerModal"
      :width="navigation.width"
    >
      {{/** MODAL CLOSE BTN **/}}
      <v-btn
        class="modal__close-btn"
        icon
        small
        absolute
        top
        left
        @click="modal = false"
      >
        <v-icon>
          mdi-close
        </v-icon>
      </v-btn>
      {{/** END MODAL CLOSE BTN **/}}

      {{/** MODAL MENU **/}}
      <v-menu
        v-if="!modal_create_mode"
        offset-y
      >
        <template v-slot:activator="{ attrs, on }">
          <v-btn
            icon
            absolute
            small
            top
            right
            v-bind="attrs"
            v-on="on"
            class="modal__menu-btn"
          >
            <v-icon>mdi-dots-horizontal</v-icon>
          </v-btn>
        </template>

        <v-list>
          <v-list-item
            v-if="canSave"
            link
            @click="$emit('deleteItemDrawer')"
            style="display:flex; align-items:center;"
          >
            <v-icon size="14" class="mr-2 mb-1">$icondelete</v-icon>
            <v-list-item-title class="text-body-2" v-text="i18n.t('modules.customdata.entity.delete')" />
          </v-list-item>
        </v-list>
      </v-menu>
      {{/** END MODAL MENU **/}}

      <v-form ref="form" v-model="form_valid" class="settings-form">
        <v-card elevation="0">

          <v-card-title class="navigation-drawer__title">
            <template v-if="modal_create_mode">{{i18n.t('modules.customdata.entity.modal_title_create')}}</template>
            <template v-else>{{i18n.t('modules.customdata.entity.modal_title')}}</template>
          </v-card-title>

          <v-card-text v-if="modal">

            <template v-for="(field, key) in get_custom_data_front_json.modal_entity">

              <v-layout
                :key="key"
                class="mb-4"
              >

                <v-icon
                  v-if="field.icon"
                  :color="field.icon_color"
                  class="mr-2"
                >{{field.icon}}</v-icon>

                <!--STRING FIELDS-->
                <template v-if="['STRING'].includes(field.type)">
                  <v-text-field
                    v-if="field.editable"
                    outlined
                    dense
                    v-model.trim="item_obj[key]"
                    :label="returnFilterKeyOrLabel(key, field)"
                    :rules="field.rules"
                    :readonly="!modal_create_mode && !canSave"
                    hide-details="auto"
                  ></v-text-field>
                  <div v-else>
                    <span class="font-weight-bold mr-2">{{returnFilterKeyOrLabel(key, field)}}:</span>
                    <span>{{item_obj[key]}}</span>
                  </div>
                </template>

                <!--TEXTAREA FIELDS-->
                <template v-if="['TEXT'].includes(field.type)">
                  <v-textarea
                    v-if="field.editable"
                    outlined
                    dense
                    v-model.trim="item_obj[key]"
                    :label="returnFilterKeyOrLabel(key, field)"
                    :rules="field.rules"
                    auto-grow
                    rows="2"
                    :readonly="!modal_create_mode && !canSave"
                    hide-details="auto"
                  ></v-textarea>
                  <div v-else>
                    <span class="font-weight-bold mr-2">{{returnFilterKeyOrLabel(key, field)}}:</span>
                    <span>{{item_obj[key]}}</span>
                  </div>
                </template>

                <!-- INTEGER FIELDS -->
                <template v-if="['INTEGER', 'BIGINT'].includes(field.type)">
                  <v-text-field
                    v-if="field.editable"
                    outlined
                    dense
                    v-model.trim="item_obj[key]"
                    :label="returnFilterKeyOrLabel(key, field)"
                    :rules="[rules.onlyDigits]"
                    :readonly="!modal_create_mode && !canSave"
                    hide-details="auto"
                  ></v-text-field>
                  <div v-else>
                    <span class="font-weight-bold mr-2">{{returnFilterKeyOrLabel(key, field)}}:</span>
                    <span>{{item_obj[key]}}</span>
                  </div>
                </template>

                <!-- BOOL FIELDS -->
                <template v-if="['BOOLEAN'].includes(field.type)">
                  <v-checkbox
                    v-if="field.editable"
                    v-model="item_obj[key]"
                    :label="returnFilterKeyOrLabel(key, field)"
                    hide-details="auto"
                    :rules="field.rules"
                    class="mt-0 mb-4"
                    :readonly="!modal_create_mode && !canSave"
                  ></v-checkbox>
                </template>

                <!-- DATE FIELDS -->
                <v-menu
                  v-else-if="['DATE'].includes(field.type)"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                  :disabled="!modal_create_mode && !canSave"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      outlined
                      dense
                      :value="formatDate(item_obj[key], false, true)"
                      :label="returnFilterKeyOrLabel(key, field)"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      clearable
                      hide-details="auto"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="item_obj[key]"
                    no-title
                    :locale="i18n.locale"
                    scrollable
                  >
                  </v-date-picker>
                </v-menu>

                <!-- MAP FIELDS -->
                <v-flex
                  v-else-if="['MAP'].includes(field.type)"
                  xs12
                  class="mb-3"
                >
                  <div class="navigation-drawer__item-title">{{ returnFilterKeyOrLabel(key, field) }}</div>
                  <div class="navigation-drawer__wrap">
                    <v-layout
                      v-if="field.editable"
                    >
                      <v-text-field
                        class="mr-4"
                        outlined
                        dense
                        :label="returnLabelFromColumn(field.longitude_key)"
                        v-model="item_obj[field.longitude_key]"
                        hide-details="auto"
                      ></v-text-field>
                      <v-text-field
                        outlined
                        dense
                        :label="returnLabelFromColumn(field.latitude_key)"
                        v-model="item_obj[field.latitude_key]"
                        hide-details="auto"
                      ></v-text-field>
                    </v-layout>

                    <CdMap
                      :mapObject="parseMapObject(JSON.stringify(
                        {
                          'longitude': item_obj[field.longitude_key],
                          'latitude': item_obj[field.latitude_key],
                        }
                      ))"
                    />
                  </div>
                </v-flex>

                <!-- SLIDER FIELDS -->
                <v-flex
                  v-else-if="
                    ['SLIDER'].includes(field.type) &&
                    checkImagesNotEmpty(field)
                  "
                  xs12
                >
                  <div class="navigation-drawer__item-title">{{ returnFilterKeyOrLabel(key, field) }}</div>
                  <div class="navigation-drawer__wrap">
                    <vue-scroll>
                      <div class="cd__image-slider">
                        <template
                          v-for="
                            (url, k) in field.img_fields
                          "
                        >
                          <CdImageSliderItem
                            :imageUrl="item_obj[url]"
                            :key="k"
                          />
                        </template>

                      </div>
                    </vue-scroll>
                  </div>
                </v-flex>

                <!-- CHART field -->
                <v-flex
                  v-else-if="['CHART'].includes(field.type)"
                  xs12
                >
                  <div class="navigation-drawer__item-title">{{ returnFilterKeyOrLabel(key, field) }}</div>
                  <div class="navigation-drawer__wrap">
                    <template
                      v-if="field.editable"
                    >
                      <v-text-field
                        class="mb-4"
                        outlined
                        dense
                        v-model="item_obj[key]"
                        hide-details="auto"
                      ></v-text-field>
                    </template>
                    <CdChart :value="item_obj[key]" />
                  </div>
                </v-flex>

                <!-- ASSOCIATION SELECT FIELDS -->
                <template
                  v-else-if="['ASSOCIATION'].includes(field.type)"
                >
                  <v-select
                    v-if="field.editable"
                    outlined
                    dense
                    :value="getItemObjValueForAssociation(key)"
                    @input="setItemObjValueForAssociation(key, $event)"
                    :label="`${returnFilterKeyOrLabel(key, field)}`"
                    :item-text="(item) => item.label || item.name"
                    item-value="id"
                    :items="custom_data_linked_tables_values[key]"
                    return-object
                    :readonly="!modal_create_mode && !canSave"
                    hide-details="auto"
                  />
                  <div v-else>
                    <span class="font-weight-bold mr-2">{{returnFilterKeyOrLabel(key, field)}}:</span>
                    <span>{{item_obj[key]}}</span>
                  </div>
                </template>

                <!-- EXPANSION PANELS items -->
                <v-expansion-panels
                  v-else-if="key === 'expansion-panels'"
                  class="mb-5"
                >
                  <v-expansion-panel
                    v-for="(field, key) in field"
                    :key="key"
                  >
                    <v-expansion-panel-header
                      :color="field.header_color ? field.header_color : ''"
                    >
                      <v-layout :align-center="true">
                        <v-icon
                          v-if="field.icon"
                          :color="field.icon_color"
                          class="mr-2"
                        >{{field.icon}}</v-icon>

                        {{returnFilterKeyOrLabel(null, field)}}
                      </v-layout>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-simple-table dense>
                        <template v-slot:default>
                          <tbody>
                            <tr
                              v-for="(item, key) in field.items"
                              :key="key"
                            >
                              <td>
                                <v-btn
                                  v-if="item.editable"
                                  small
                                  icon
                                  @click="item.edit_toggle = !item.edit_toggle"
                                  color="green"
                                  class="mr-2"
                                >
                                  <v-icon>mdi-pencil-outline</v-icon>
                                </v-btn>

                                <v-icon
                                  v-if="item.icon"
                                  :color="item.icon_color"
                                  class="mr-2"
                                >{{item.icon}}</v-icon>


                                {{ returnLabelFromColumn(key, item) }}
                              </td>
                              <td>
                                <v-layout
                                  :align-center="true"
                                >
                                  <div
                                    class="my-1"
                                    v-show="item.edit_toggle"
                                  >
                                    <template
                                      v-if="item.editable"
                                    >
                                      <!--STRING FIELDS-->
                                      <v-text-field
                                        v-if="['STRING'].includes(item.type)"
                                        outlined
                                        dense
                                        v-model.trim="item_obj[key]"
                                        :readonly="!modal_create_mode && !canSave"
                                        hide-details="auto"
                                        background-color="white"
                                      />

                                      <!--TEXTAREA FIELDS-->
                                      <v-textarea
                                        v-else-if="['TEXT'].includes(item.type)"
                                        outlined
                                        dense
                                        v-model.trim="item_obj[key]"
                                        auto-grow
                                        rows="2"
                                        :readonly="!modal_create_mode && !canSave"
                                        hide-details="auto"
                                        background-color="white"
                                      />

                                      <!-- INTEGER FIELDS -->
                                      <v-text-field
                                        v-else-if="['INTEGER', 'BIGINT'].includes(item.type)"
                                        outlined
                                        dense
                                        v-model.trim="item_obj[key]"
                                        :rules="[rules.onlyDigits]"
                                        :readonly="!modal_create_mode && !canSave"
                                        hide-details="auto"
                                        background-color="white"
                                      ></v-text-field>

                                      <!-- BOOL FIELDS -->
                                      <v-checkbox
                                        v-else-if="['BOOLEAN'].includes(item.type)"
                                        v-model="item_obj[key]"
                                        class="mt-0 mb-4"
                                        :readonly="!modal_create_mode && !canSave"
                                        hide-details="auto"
                                      ></v-checkbox>

                                    </template>
                                  </div>

                                  <div
                                    v-show="!item.edit_toggle"
                                  >
                                    {{ item_obj[key] }}
                                  </div>
                                </v-layout>

                              </td>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>

                <!-- create_association_row -->
                <template
                  v-else-if="['CREATE_ASSOCIATION_ROW'].includes(field.type)"
                >
                  <div>
                    <template v-if="item_obj[field.to]">
                      <v-alert
                        type="warning"
                        dense
                        class="ma-0"
                      >
                        <span class="mr-2">{{field.label_if_result}}:</span>
                        {{
                          ('show_if_result' in field)
                            ? item_obj[field.show_if_result]
                            : item_obj[field.to]
                        }}
                      </v-alert>

                    </template>
                    <template v-else>
                      <div v-if="checkAssociationPermission(field)">
                        <v-btn
                          :color="field.icon_color"
                          dark
                          elevation="0"
                          small
                          :loading="create_association_row_loading"
                          @click="createFieldsFromAssociation(field)"
                        >{{field.label}}</v-btn>


                        <DynamicMainModalEntity
                          ref="modalCreateEntityForm"
                          v-model="create_association_row_dialog"
                          :canSave="canSave"
                          :modal_create_mode="true"
                          :multiple_entity="multiple_entity"

                          :custom_drawer_title="field.label"

                          :entity_fields="create_association_row_fields"
                          :item_obj="create_association_row_obj"

                          @save="saveAssociationRow(field)"
                        />
                      </div>
                    </template>
                  </div>
                </template>
                <!-- END create_association_row -->

              </v-layout>

            </template>

          </v-card-text>

          <v-footer>
            <v-layout justify-start>
              <v-btn
                class="mr-4"
                color="primary"
                elevation="0"
                @click="$emit('save')"
                small
                :disabled="!modal_create_mode && !canSave"
              >
                <template v-if="modal_create_mode">{{i18n.t('common.create')}}</template>
                <template v-else>{{i18n.t('common.save')}}</template>

              </v-btn>
            </v-layout>
          </v-footer>

        </v-card>
      </v-form>

    </v-navigation-drawer>
  </div>

</template>

<script>
import Vue from "vue";
import axios from "axios";
import i18n from '@/lang/i18n';
import {mapGetters} from "vuex";
import CdMap from "@/modules/CustomData/views/customdata/NewComponents/CdMap.vue";
import CdImageSliderItem from "@/modules/CustomData/views/customdata/NewComponents/CdImageSliderItem.vue";
import CdChart from "@/modules/CustomData/views/customdata/NewComponents/CdChart.vue";
import DynamicMainModalEntity from "@/modules/CustomData/components/DynamicMainModalEntity.vue";

export default {
  name: "DynamicModalEntity",
  components: {
    DynamicMainModalEntity,
    CdChart,
    CdImageSliderItem,
    CdMap
  },

  props: {
    value: {
      type: Boolean,
      default: false,
    },
    modal_create_mode: {
      type: Boolean,
      default: false,
    },
    canSave: {
      type: Boolean,
      default: false,
    },


    entity_fields: {
      type: Object,
      default: () => {},
    },
    item_obj: {
      type: Object,
      default: () => {},
    },

    multiple_entity: {
      type: String,
      default: '',
    },
  },

  data: () => ({
    i18n,
    form_valid: true,

    bot_id: null,

    //Panel resize
    navigation: {
      width: localStorage.getItem("drawerModalWidth") || '50%',
      borderSize: 8
    },
    //Panel resize

    create_association_row_dialog: false,
    create_association_row_loading: false,
    create_association_row_obj: {
      id: null,
    },
    create_association_row_fields: {},
  }),

  mounted() {
    this.bot_id = this.$route?.params?.id

    //Panel resize
    this.setBorderWidth();
    this.setEvents();
    //Panel resize
  },

  beforeDestroy: function () {
    this.setEvents(true); // Pass true to remove EventListeners
  },

  computed: {
    ...mapGetters([
      'get_custom_data_front_json',
      'custom_data_linked_tables_values',
      'rules',
      'engine_settings',
      'custom_data_tables',
      'user',
    ]),

    modal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      }
    }
  },

  methods: {

    //Panel resize
    setBorderWidth() {
      let i = this.$refs.drawerModal.$el.querySelector(
        ".v-navigation-drawer__border"
      );
      i.style.width = this.navigation.borderSize + "px";
      i.style.cursor = "ew-resize";
    },

    setEvents(clean = false) {
      const minSize = this.navigation.borderSize;
      const el = this.$refs.drawerModal.$el;
      const drawerBorder = el.querySelector(".v-navigation-drawer__border");
      const vm = this;
      const direction = el.classList.contains("v-navigation-drawer--right")
        ? "right"
        : "left";

      function resize(e) {
        document.body.style.cursor = "ew-resize";
        let f = direction === "right"
          ? window.innerWidth - e.clientX
          : e.clientX;
        el.style.width = f + "px";
      }

      if (clean) {
        drawerBorder.removeEventListener("mousedown", this.handleMouseDown, false);
        document.removeEventListener("mouseup", this.handleMouseUp, false);
      } else {
        drawerBorder.addEventListener(
          "mousedown",
          function(e) {
            if (e.offsetX < minSize) {
              el.style.transition ='initial';
              document.addEventListener("mousemove", resize, false);
            }
          },
          false
        );

        document.addEventListener(
          "mouseup",
          function() {
            el.style.transition ='';

            if(window.innerWidth < 960){
              vm.navigation.width = parseInt(window.innerWidth);
            } else {
              vm.navigation.width = el.style.width;
            }

            document.body.style.cursor = "";
            document.removeEventListener("mousemove", resize, false);

            localStorage.setItem("drawerModalWidth", vm.navigation.width);
          },
          false
        );
      }
    },
    //Panel resize end


    //fields
    returnFilterKeyOrLabel(key, item) {
      const label_from_column_name = this.entity_fields?.[item?.label_from_column_name]?.label

      return item?.label || label_from_column_name || key
    },
    returnLabelFromColumn(key, item = null) {
      let label = this.entity_fields[key]?.label || key
      if(item && item?.label) label = item.label
      return label
    },
    formatDate(isoTimeString, short = false, shortest = false) {
      if (!isoTimeString) return;
      const date = new Date(isoTimeString);
      const day = date.getDate().toString().padStart(2, '0');
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const year = date.getFullYear();
      const hours = date.getHours().toString().padStart(2, '0');
      const minutes = date.getMinutes().toString().padStart(2, '0');
      const seconds = date.getSeconds().toString().padStart(2, '0');

      if (short) {
        return `${day}.${month}.${year.toString().slice(-2)} ${hours}:${minutes}`;
      } else if (shortest) {
        return `${day}.${month}.${year}`;
      } else {
        return `${day}.${month}.${year} ${hours}:${minutes}:${seconds}`;
      }
    },
    //association field
    getItemObjValueForAssociation(key) {
      let item =  this.custom_data_linked_tables_values[key].filter((i) => {
        if (i.name === this.item_obj[key]){
          this.item_obj[key] = JSON.parse(JSON.stringify(i))
          return i
        } else if (i.name === this.item_obj[key]?.name) {
          return this.item_obj[key]
        }
      })

      return item[0]
    },
    setItemObjValueForAssociation(key, value) {
      (value) ? this.item_obj[key] = value : delete this.item_obj[key]
    },


    // MAP
    parseMapObject(str) {
      try {
        if (str && str.trim() !== '') {
          return JSON.parse(str)
        } else {
          return { longitude: 0, latitude: 0 }
        }
      } catch (error) {
        console.error('Error parsing JSON:', error)
        return { longitude: 0, latitude: 0 }
      }
    },

    checkImagesNotEmpty(field) {
      if(!field?.img_fields?.length) return false

      let success = false

      for(const i in field?.img_fields) {
        if(this.item_obj?.[field.img_fields[i]]) {
          success = true
        }
      }

      return success
    },

    async createFieldsFromAssociation(field) {
      this.create_association_row_loading = true

      this.create_association_row_fields = await JSON.parse(JSON.stringify(
        this.custom_data_tables.find(item => item.name === field.from).columns_json
      ))

      for(let i in this.create_association_row_fields) {
        if('association' in this.create_association_row_fields[i]) {
          await this.$store.dispatch('_customDataAxiosGetLinkedTablesValues', {
            modelName: this.create_association_row_fields[i].association.model_name,
            modelField: this.create_association_row_fields[i].association.model_field,
            linkedColumn: i,
            bot_id: this.bot_id,
            modelFieldLabel: this.create_association_row_fields[i].association?.model_field_label
          })
        }
      }

      this.create_association_row_loading = false
      this.create_association_row_dialog = true
    },

    //save row in association table
    async saveAssociationRow(field) {

      let request = this._.cloneDeep(this.create_association_row_obj);

      for(let i in request) {
        if (typeof request[i] === 'object') {
          request[i] = request[i]?.id || null
        }
      }

      const id = request?.id || null


      let success = false;
      const token = Vue.$cookies.get('token_e')
      const engine_url = this.engine_settings.active_url;
      const bot_id = this.bot_id;
      const model_field = field.from

      let url = `${engine_url}/kw/custom_modules/${model_field}/update/${id}/?bot_id=${bot_id}`
      if(id===null)
        url = `${engine_url}/kw/custom_modules/${model_field}/create/?bot_id=${bot_id}`

      await this.$store.dispatch('updateAjaxDialog', [true, true]);

      await axios
        .post(
          url,
          request,
          {
            headers: {
              'X-CSRF-TOKEN': token,
            },
          }
        )
        .then(
          async (response) => {
            await this.$store.dispatch('updateAjaxDialog', [false, false]);
            if (
              response.data &&
              response.data.data?.id &&
              response.data.status &&
              response.data.status === 'success'
            ) {
              const data = {
                id: this.item_obj.id,
                [field.to]: response.data.data.id,
              }

              await this.$root.$emit('CustomDataTab', data)
              success = true;
            }
          },
          (err) => {
            if (
              err &&
              err.response &&
              err.response.data &&
              err.response.data.message_alias
            ) {
              let message_alias = err.response.data.message_alias;

              if (message_alias === 'access_error') {
                this.$store.dispatch('updateAjaxDialogError', [
                  err,
                  this.$t('common.error'),
                  this.$t('common.error_permitions'),
                ]);
              } else {
                this.$store.dispatch('updateAjaxDialogError', [
                  err,
                  this.$t('common.error'),
                  this.$t('common.error_save'),
                ]);
              }
            } else {
              this.$store.dispatch('updateAjaxDialogError', [
                err,
                this.$t('common.error'),
                this.$t('common.error_save'),
              ]);
            }
          }
        ).finally(() => {
          this.create_association_row_dialog = true
          this.$emit('input', false);
        });

      return success;
    },

    checkAssociationPermission(field) {
      let access = true

      if(field?.save_by_permission) {
        access =
          this.user.role.permissions
            .custom_data?.models?.[this.bot_id]?.[field?.from]?.canAdd

        //permission for Root Admin
        if(this.user?.root === 1) access = true
      }

      return access
    }
  },

  watch: {
    value: function(val) {
      if(!val && this.create_association_row_dialog) {
        this.create_association_row_dialog = false
      }
    }
  },
}

</script>

<style lang="scss" scoped>
.settings-form::v-deep {
  height: 100%;

  .v-card {
    height: 100%;
    display: flex;
    flex-direction: column;

    .v-card__text{
      padding-top: 5px;
      flex-grow: 1;
      overflow: auto;
    }
  }
}

.modal__close-btn {
  z-index: 1;
  top: 9px;
}

.modal__menu-btn {
  z-index: 1;
  top: 48px;

  &::before {
    opacity: 0.08;
  }
}


.v-navigation-drawer {
  &::v-deep .v-navigation-drawer__border {
    border-right: 1px solid rgba(0, 0, 0, 0.05);
    background-color: rgba(0, 0, 0, 0.08) !important;

    &:after {
      content: '·······';
      color: #000;
      transform: rotate(90deg);
      position: absolute;
      top: 50%;
      left: -14px;
      font-size: 24px;
      line-height: 0.3;
      pointer-events: none;
    }

    @media screen and (max-width: 959px){
      display: none;
    }
  }
}
.navigation-drawer {
  &__title {
    font-size: 18px;
    margin-top: 30px;
    padding-left: 27px;
    padding-right: 50px;
    padding-bottom: 10px;
    text-align: left;
  }

  &__wrap {
    padding: 20px 15px;
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.38);
    margin-bottom: 25px;

    .cd__image-slider {
      display: flex !important;
      gap: 16px !important;
      height: 400px !important;
    }
  }

  &__item-title {
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 5px;
    text-align: left;
  }
}

</style>